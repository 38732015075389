import React from 'react'
import styled, {ThemeProvider} from "styled-components";
import design from "../design";
import {Helmet} from "react-helmet";
import { Div } from 'components'
import { pickLocale } from 'utils'

const BareScale = ({ className, children }) => (<div className={className}>{children}</div>)
const Scale = styled(BareScale)`
  zoom: calc( ( 100%/(375/1000) ) );

  @media screen and (min-width: 1024px) {
    zoom: calc( ( 100% / (1280/1000) ) / 1.5 );
    ${({ scale }) => scale ? `zoom: ${scale}` : ``};
  }

  ${[240,320,360,375,412,414,480,540,600,601,640,667,720,750,768,798,812,834,846,962].map(width => {
    return `
    @media screen and (min-width: ${width}px) and (max-width: ${width}px) {
      zoom: ${width/375};
    }
    `
  })}
  ${[1024-40,1024,1080,1280-40,1280,1366,1400,1440,1536-40,1536,1600-40,1600,1680-40,1680,1920-40,1920,2048,2160,2560-40,2560,3840-40,3840,4096].map(width => {
    let zoom = width/1280
    if (width > 1280) {
      zoom /= 1.5
    }
    return `
    @media screen and (min-width: ${width}px) and (max-width: ${width}px) {
      zoom: ${zoom};
    }
    `
  })}

  transform-origin: ${({ origin }) => origin};
`
const Outer = styled.div`
  background: ${design.color.bgLight};

  ${({ gradient }) => gradient ? `background: linear-gradient(rgb(57, 120, 153) 0%, rgb(70, 175, 196) 3%, rgb(199, 236, 242) 5%, rgb(197, 235, 241) 15%, rgb(238, 251, 253) 18%);` : ``}
  @media screen and (min-width: 1024px) {
    ${({ gradient }) => gradient ? `background: linear-gradient(to bottom,  rgba(57,120,153,1) 0%,rgba(70,175,196,1) 150px,rgba(119,208,221,1) 300px,rgba(238,251,253,1) 700px);` : ``}
  }
`
const BareInner = ({ width, children, ...rest }) => <Div {...rest}>{children}</Div>
const Inner = styled(BareInner)`
  width: 375px;
  @media screen and (min-width: 1024px) {
    width: 1280px;
  }
  margin-left: auto;
  margin-right: auto;
`
const Background = styled.div`
  position: relative;
  padding: ${({ padding }) => padding};

  @media screen and (max-width: 1024px) {
     overflow-x: hidden;
  }
`

class Root extends React.Component {
  state = {
    windowSize: { innerWidth: 500 }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    let w = {
      innerWidth: 600,
    }
    if (typeof window !== 'undefined') {
      w = window
    }
    const windowSize = {
      innerHeight: w.innerHeight,
      innerWidth: w.innerWidth,
      outerHeight: w.outerHeight,
      outerWidth: w.outerWidth,
    }
    this.setState({ windowSize })
  }

  render() {
    const { children, className } = this.props
    const { windowSize } = this.state

    let scale, width;
    const windowWidth = windowSize.outerWidth
    console.info('windowWidth', windowWidth)

    if (windowWidth < 1024) {
      width = 375
    } else {
      width = 1280
    }

    scale = windowWidth/width
    let origin = `top left`

    if (windowWidth > 375 && windowWidth < 1024) {
      origin = `top center`
    }
    if (windowWidth > 1280 && scale > 1) {
      scale = scale/1.5
      origin = `top center`
    }

    console.info('scale', scale)
    console.info('windowWidth', windowWidth)

    const padding = windowWidth > 1024 ? `24px 48px` : `24px 36px`

    const locale = pickLocale(['fr', 'en'])

    return (
      <ThemeProvider theme={design.theme}>
        <Outer className={'gradient'} gradient={this.props.gradient}>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            <html lang={locale || 'en'} />
            <title>Stairlin</title>

            {/* Facebook */}
            <script>{`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2542849449262332');
              fbq('track', 'PageView');
            `}</script>
            <noscript>{`
              <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=2542849449262332&ev=PageView&noscript=1" />
            `}</noscript>

            {/* LinkedIn */}
            <script type="text/javascript">{`
              _linkedin_partner_id = "2213249";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
              </script><script type="text/javascript">
              (function(){var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);})();
            `}</script>
            <noscript>{`
              <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2213249&fmt=gif" />
            `}</noscript>
          </Helmet>
          <Scale scale={scale} origin={origin}>
            <Inner pt={[64, 56]} width={width}>
              <Background tag={'section'} id={`bg`} className={className} _padding={padding}>
                {children}
                {/*<div>windowWidth: {windowWidth}</div>*/}
              </Background>
            </Inner>
          </Scale>
        </Outer>
      </ThemeProvider>
    )
  }
}

export default Root
