import React from 'react'
import styled from 'styled-components'
import design from 'design'
import { Div } from 'components'
import { system, background, space, color, typography, width, height, minHeight, maxWidth, display, flex, flexWrap, justifyContent, alignItems, flexDirection, boxShadow, position, zIndex, top, right, bottom, left, border, borderRadius } from 'styled-system'

const Left = styled(Div)`
  display: none;
  position: absolute;
  top: 0;
  left: calc(-100% + 2px);
  bottom: 0;
  background: ${({ bg }) => bg ? bg : design.color.primary};
  width: 100%;
  @media screen and (min-width: 1280px) {
    display: block;
  }
`
const Right = styled(Div)`
  display: none;
  position: absolute;
  top: 0;
  left: calc(100% - 2px);
  bottom: 0;
  background: ${({ bg }) => bg ? bg : design.color.primary};
  width: 100%;
  @media screen and (min-width: 1280px) {
    display: block;
  }
`

export default ({ bg, className, display, left = true, right = true }) => (
  <>
    {left && <Left className={className} _display={display} bg={bg} />}
    {right && <Right className={className} _display={display} bg={bg} />}
  </>
)
