import React from 'react'
import styled from 'styled-components'
import { fontSize, textAlign, space, color, display } from 'styled-system'
import { Link } from "gatsby"
import design from '../design'

const Input = ({ className, children, placeholder, name, type, value, id, style, onChange, ...rest }) => {
  return (
    <input className={className} id={id} name={name} type={type} value={value} style={style} placeholder={placeholder} onChange={onChange} />
  )
}

export default styled(Input)`
  appearance: none;
  //min-width: 300px;
   width: 100%;
  border: 1px solid ${design.color.grayLight};
  border-radius: 5px;
  //21px 14px 12px medium
  //16px calc
  padding: ${({ big }) => big ? design.media.switch(`14px 12px`, `22px 24px`) : `11px 16px`};
  font-size: ${({ big }) => big ? design.media.switch(21, 28) : 14}px;
  font-weight: 400;
  outline: none;
  color: ${({ color = design.color.grayDark }) => color};
  background: ${({ background = design.color.white }) => background};
  &:focus {
    // border-color: red;
  }
  ::placeholder {
    color: ${design.color.grayLight};
  }
  ${display}
  ${space}
  ${fontSize}
`
