import React from 'react'
import {color, display, fontSize, space, textAlign, width, border } from 'styled-system'
import styled from 'styled-components'

const BareTable = ({ className, children }) => {
  return (
    <table className={className}>{children}</table>
  )
}

const Table = styled(BareTable)`
  border-collapse: collapse;
  ${fontSize}
  ${textAlign}
  ${space}
  ${color}
  ${display}
  ${width}
`

const BareTh = ({ className, children }) => {
  return (
    <th className={className}>{children}</th>
  )
}

const Th = styled(BareTh)`
  ${fontSize}
  ${textAlign}
  ${space}
  ${color}
  ${display}
  ${border}
`

const BareTd = ({ className, children, style }) => {
  return (
    <td className={className} style={style}>{children}</td>
  )
}

const Td = styled(BareTd)`
  ${fontSize}
  ${textAlign}
  ${space}
  ${color}
  ${display}
  ${border}
`

const BareTr = ({ className, children }) => {
  return (
    <tr className={className}>{children}</tr>
  )
}

const Tr = styled(BareTr)`
  ${fontSize}
  ${textAlign}
  ${space}
  ${color}
  ${display}
`

Table.Tr = Tr
Table.Th = Th
Table.Td = Td

export default Table