import React from 'react'
import styled from 'styled-components'
import design from 'design'

const BareTooltip = ({ to, children, className, show, label, top, left, ...rest }) => {
  if (!show) return null;
  return (
    <div className={className} {...rest}>{label}</div>
  )
}

export default styled(BareTooltip)`
  position: absolute;
  pointer-events: none;
  font-weight: 700;
  font-size: 18px;
  background: rgba(0,0,0,.7);
  border-radius: 5px;
  color: #fff;
  ${({ top }) => top ? `top: ${top}px;` : ``}
  ${({ left }) => left ? `left: ${left}px;` : ``}
  padding: 8px 12px;
  :after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    
    border-top-color: rgba(0,0,0,.7);
    border-top-style: solid;
    border-top-width: 6px;
  }
`
