import React from 'react'
import styled from 'styled-components'
import { layout, space, color, display, border, boxShadow, position } from 'styled-system'

const BareImg = ({ className, id, src, alt, style, width, height, ...rest }) => {
  const Tag = `img`
  return (<Tag src={src} alt={alt} className={className} id={id} style={style} />)
}

export default styled(BareImg)`
  ${({ shadow }) => {
    return shadow ? `filter: drop-shadow(8px 8px 32px rgba(33, 57, 61, 0.2));` : ''
  }}
  ${space}
  ${color}
  ${display}
  ${position}
  ${layout}
  ${border}
  ${boxShadow}
`
