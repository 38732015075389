import parser from 'accept-language-parser';

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getYoutubeId(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11)? match[7] : false;
}

export function openUrl(url, options) {
  return e => {
    window.open(url)
  }
}

export function pickLocale(availableLocales = ['en', 'fr']) {
  if (typeof window === "undefined") return null

  // support for HTML 5.1 "navigator.languages"
  const nav = window.navigator
  if (Array.isArray(nav.languages)) {
    return parser.pick(
      availableLocales,
      nav.languages.join(";"),
      { loose: true }, // Allow to strip regional preferences
    )
  }

  // support for other well known properties in browsers
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  return parser.pick(
    availableLocales,
    browserLanguagePropertyKeys.filter(k => !!k).join(";"),
    { loose: true }, // Allow to strip regional preferences
  )
}

export const format = (lang) => {
  const at = lang === 'fr' ? 'à' : 'at'
  return {
    MDYH: `MMMM D, YYYY [${at}] H:mm`,
    DMY: `D MMMM YYYY`,
    HM: `HH:mm`,
    dDM: `dddd, D MMMM`,
  }
}

export default {
  getRandomInt,
  getYoutubeId,
  openUrl,
  format,
}
