import React from 'react'
import styled from 'styled-components'
import { system, background, space, color, typography, width, height, minHeight, maxWidth, display, flex, flexWrap, justifyContent, alignItems, flexDirection, boxShadow, position, zIndex, top, right, bottom, left, border, borderRadius, layout } from 'styled-system'

const BareDiv = (props) => {
  let { className, onClick, children, tag = 'div', id, style, ...rest } = props
  const Tag = tag
  return (<Tag className={className} onClick={onClick} id={id} style={style}>{children}</Tag>)
}

export default styled(BareDiv)`
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${typography}
  ${space}
  ${background}
  ${color}
  ${layout}
  ${display}
  ${flex}
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
  ${alignItems}
  ${boxShadow}
  ${position}
  ${zIndex}
  ${top}
  ${right}
  ${bottom}
  ${left}
  ${border}
  ${borderRadius}
`
