import React from 'react'
import styled from 'styled-components'
import { layout, space, color, display, border } from 'styled-system'

const BareBr = ({ className }) => (<br className={className} />)

export default styled(BareBr)`
  ${display}
  ${layout}
`
