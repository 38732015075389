import React from 'react'
import styled from "styled-components";
import design from "design";
import { fontSize, space, color, display } from 'styled-system'
import { Link } from 'gatsby'

const TempLink = ({ to, children, className, id, style, ...rest }) => {
  if (to && to.indexOf('://') !== -1) {
    return (<a className={className} href={to} id={id} style={style}>{children}</a>)
  }
  return (<Link className={className} to={to} id={id} style={style}>{children}</Link>)
}
//Link
const FooterLink = styled(TempLink)`
  // background: gray;
  display: block;
  line-height: 1.542;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  color: ${design.color.white};
  text-decoration: none;
  ${fontSize}
  ${space}
  ${color}
  ${display}
`

export default FooterLink
