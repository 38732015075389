import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  ${({ justifyContent }) =>
  justifyContent ? `justify-content: ${justifyContent}` : ''};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : '')};
`

export default Row
