import React from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import design from 'design'
import { fontSize, fontWeight, textAlign, space, color, display, position, border } from 'styled-system'
// import SmoothScroll from 'smooth-scroll'
let scroll
if (typeof window !== `undefined`) {
  scroll = require("smooth-scroll")()
  window.__scroll = scroll
}

const CustomLink = (props) => {
}

const onMainNavClick = e => {
  if (typeof window !== 'undefined') {
    if (window.scrollTo) {
      window.scrollTo(null, 0)
      if (scroll) {
        scroll.cancelScroll()
      }
    }
    if (window.__closeMobileNav) {
      window.__closeMobileNav()
    }
    if (window.__closeDesktopNav) {
      window.__closeDesktopNav()
    }
  }
}

const clickId = id => e => {
  const element = document.getElementById(id)
  const bb = element.getBoundingClientRect()
  const stickyHeight = document.getElementById('sticky').offsetHeight;
  const innerWidth = window.innerWidth
  const topPadding = window.innerWidth >= 1024 ? 40 : 24
  let scale = innerWidth/(window.innerWidth >= 1024 ? 1280 : 375);
  if (innerWidth > 1280) {
    scale /= 1.5
  }

  const by = bb.top - stickyHeight - topPadding
  const to = by*scale + window.scrollY
  scroll.animateScroll(to, 1, { speed: 300 });

  e.preventDefault();
  return false;
}

const TempLink = ({ to, children, className, id, style, target, ...rest }) => {
  if (scroll) {
    scroll.cancelScroll()
  }
  if (to && (to.indexOf('://') !== -1)) {
    return (<a className={className} href={to} id={id} style={style} target={target}>{children}</a>)
  }
  if (to && (to.indexOf('#') !== -1)) {
    const toParts = to.split('#')
    const justId = toParts[1]
    return (<a className={`smooth ${className}`} onClick={clickId(justId)} id={id} style={style} target={target}>{children}</a>)
  }
  return (<Link onClick={onMainNavClick} className={className} to={to} id={id} style={style} target={target}>{children}</Link>)
}

//Link
export default styled(TempLink)`
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  :visited, :link, :active {
    color: ${design.color.text};
  }
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${space}
  ${color}
  ${display}
  ${position}
  ${border}
`
