import React from 'react'
import styled from 'styled-components'
import { fontSize, textAlign, space, color, display, fontWeight, minWidth, layout, position } from 'styled-system'
import design from '../design'
import {Div, Link, Text} from 'components'
import Moment from "react-moment";
import moment from 'moment'
import BareArrow from "../images/arrow.inline.svg";
import {getRandomInt} from "../utils";

const Arrow = styled(BareArrow)`
  width: 8px;
  height: 14px;
  transform: rotate(-90deg);
`

const Day = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 32px;
  width: 36px;
  height: 36px;
  color: rgba(35, 35, 35, 0.6);
  border-radius: 50%;
  margin: 4px 6px 4px 6px;
  ${({ empty }) => empty ? '' : `
    background-image: linear-gradient(45deg, #d1d1d1 9.09%, #f6f6f6 9.09%, #f6f6f6 50%, #d1d1d1 50%, #d1d1d1 59.09%, #f6f6f6 59.09%, #f6f6f6 100%);
    background-size: 8px 8px;
  `}
  ${({ primary }) => primary && `background: ${design.color.primary}; color: white; font-weight: 700;`}
  ${({ secondary }) => secondary && `background: #E6F6F8; color: ${design.color.primary};`}
`

export default class Calendar extends React.Component {
  constructor(props) {
    super(props)
    let dateObject = moment()
    if (moment().endOf('month') === dateObject) {
      dateObject.add(1, 'day')
    }
    this.state = {
      dateObject
    }
  }

  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d");
    return firstDay;
  };

  currentDay = () => {
    return parseInt(moment().format("D"), 10);
  };

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };

  onNext = () => {
    this.setState({
      dateObject: this.state.dateObject.add(1, 'month')
    });
  };
  onPrev = () => {
    this.setState({
      dateObject: this.state.dateObject.subtract(1, 'month')
    });
  };

  render() {
    const { className, lang, href, id, children, ...rest } = this.props
    moment.locale(lang, {
      // week: {
      //   dow: 1
      // }
    });

    let weekDaysShort = moment.weekdaysShort();
    weekDaysShort.push(weekDaysShort.shift());
    console.log(weekDaysShort, this.daysInMonth(), this.state.dateObject.month())

    let days = []
    for (let i = 1; i < this.firstDayOfMonth(); i++) {
      days.push(<Day empty />);
    }
    for (let i=1; i<=this.daysInMonth(); i++) {
      let secondary = false
      let primary = (i === this.currentDay() && this.state.dateObject.month() === moment().month())
      if (this.state.dateObject.month() > moment().month() || (this.state.dateObject.month() === moment().month() && i > this.currentDay())) {
        secondary = true
      }
      // const iWeekDayN = this.state.dateObject.clone().date(i).weekday()
      // if (iWeekDayN === 6 || iWeekDayN === 5) {
      //   secondary = false
      // }
      if (Math.random() > 0.7) {
        secondary = false
      }
      days.push(
        <Day primary={primary} secondary={secondary}>{i}</Day>
      )
    }

    return (
      <Div userSelect={'none'} width={375} margin={'0 auto'}>
        <Div p={4} pb={`18px`}>
          <Div px={4} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Div cursor={'pointer'} onClick={e => { this.onPrev(); }} borderRadius={5} display={'flex'} justifyContent={'center'} alignItems={'center'} _opacity={0.5} _border={`1px solid #f1efef`} boxShadow={'black'}  width={38} height={38}><Arrow style={{ transform: 'none' }}/></Div>
            <Div><Text fontSize={3} fontWeight={700}><Moment locale={lang} date={this.state.dateObject} format={`MMMM YYYY`} /></Text></Div>
            <Div cursor={'pointer'} onClick={e => { this.onNext(); }} borderRadius={5} display={'flex'} justifyContent={'center'} alignItems={'center'} boxShadow={'black'} width={38} height={38}><Arrow style={{ transform: 'rotate(180deg)' }}/></Div>
          </Div>
        </Div>

        <Div mb={2} p={`0 38px 0 34px`} display={'flex'} justifyContent={'space-between'}>
          {weekDaysShort.map(dayName => (
            <Text fontWeight={700} fontSize={3}>{dayName[0]}</Text>
          ))}
        </Div>

        <Div m={'0 auto'} width={336} display={'flex'} flexWrap={'wrap'}>
          {days}
        </Div>
      </Div>
    )
  }
}
