import React from 'react'
import styled from 'styled-components'
import { typography, textAlign, space, color, display, fontWeight, minWidth, layout, position } from 'styled-system'
import design from '../design'
import { Link } from 'components'

const BareTitle = ({ className, tag = 'h1', href, id, children, style, ...rest }) => {
  const Tag = tag
  if (href) {
    return (
      <Tag className={className} id={id} style={style}>
        <Link style={{ color: rest.color, fontWeight: rest.fontWeight }} id={id} to={href}>{children}</Link>
      </Tag>
    )
  }
  return (
    <Tag className={className} id={id} style={style}>{children}</Tag>
  )
}

export default styled(BareTitle)`
  font-weight: 700;
  ${typography}
  ${textAlign}
  ${space}
  ${position}
  ${layout}
  ${color}
  ${display}
  * {
    font-weight: 700;
    ${typography}
    ${textAlign}
    ${color}
  }
`
