import React from 'react'
import styled from 'styled-components'
import { color, space, layout, width, buttonStyle, border, borderColor, boxShadow, fontSize, textAlign, minWidth, display, flexbox } from 'styled-system'
import { Link } from "gatsby"
import design from '../design'
import BareArrow from 'images/btnArrow.svg'

const ElBareArrow = ({ className }) => (<BareArrow className={className} />)

const Arrow = styled(ElBareArrow)`
  ${({ color }) => color ? `path { fill: ${color}; }` : ``}
  ${({ ml }) => ml ? `margin-left: ${ml};` : `margin-left: 12px;`}
  ${({ height }) => height && height !== true ? `height: ${height};` : ``}
`

const BareButton = ({ className, arrowColor, href, id, style, type, onClick, children, arrow, arrowMargin, ...rest }) => {
  let Component = href ? 'a' : Link
  if (type || onClick) Component = 'button'
  if (!href && !rest.to && !onClick) {
    Component = 'button'
  }
  return (
    <Component className={className} type={type} onClick={onClick} href={href} style={style} id={id}>{children}{arrow ? <Arrow color={arrowColor} ml={arrowMargin} height={arrow} /> : null}</Component>
  )
}

const Button = styled(BareButton)`
  //display: inline-block;
  user-select: none;
  line-height: 1.4;
  outline: none;
  display: inline-flex;
  background: transparent;
  align-items: center;
  justify-content: center;

  svg {
    fill: #54d2df;
  }

  ${({ border }) => border ? `border: 1px solid #FFF` : `border: none`};
  border-radius: 5px;
  //padding: ${({ big }) => big ? design.media.switch(`14px 19px`, `22px 31px`) : `8px 24px`};
  font-size: ${({ big }) => big ? design.media.switch(`21px`, `28px`) : `18px`};
  font-weight: 700;
  ${({ shadow }) => shadow ? `box-shadow: 8px 8px 32px 0px rgba(119,208,221,0.5);
` : ``}
  text-decoration: none;
  cursor: ${({ onClick, href, to }) => (onClick || href || to) ? `pointer` : `default`};
  // color: ${({ color = '#FFF' }) => color};
  white-space: nowrap;
  ${display}
  ${color}
  ${border}
  ${borderColor}
  ${space}
  ${width}
  ${boxShadow}
  ${fontSize}
  ${buttonStyle}
  ${minWidth}
  ${textAlign}
  ${flexbox}
  //background: ${({ background = 'transparent' }) => background};
  ${({ marginLeft }) => marginLeft ? `margin-left: ${marginLeft}px` : ``}
`

Button.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes
}

Button.defaultProps = {
  color: design.color.text,
  px: 4,
  py: 2,
}

export default Button
