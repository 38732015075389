import React from 'react'
import styled from 'styled-components'
import { typography, color, background, space, width, height, buttonStyle, border, borderColor, boxShadow, display, flex, justifyContent, alignItems, textAlign, position, overflow } from 'styled-system'
import design from 'design'

const BareBox = ({ className, tag = 'div', id, style, children, ...rest }) => {
  const Tag = tag
  return (
    <Tag className={className} id={id} style={style}>{children}</Tag>
  )
}

const Box = styled(BareBox)`
  border-radius: 5px;
  margin: 24px 0;
  ${({ marginTop }) => marginTop ? `margin-top: ${marginTop}px;` : ``}
  ${({ paddingTop }) => paddingTop ? `padding-top: ${paddingTop}px;` : ``}
  ${space}
  ${position}
  ${width}
  ${height}
  ${color}
  ${background}
  ${boxShadow}
  ${display}
  ${display}
  ${flex}
  ${justifyContent}
  ${alignItems}
  ${textAlign}
  ${overflow}
  ${typography}
`

Box.defaultProps = {
  // color: design.color.white,
  boxShadow: `black`,
}

export default Box
