import React from 'react'
import styled from 'styled-components'
import { fontSize, border, fontWeight, textAlign, typography, system, space, color, display, flexbox, position } from 'styled-system'
// import flexbox from '@styled-system/flexbox'

const BareText = ({ className, tag = 'p', children, id, style, ...rest }) => {
  const Tag = tag
  return (
    <Tag className={className} id={id} style={style}>{children}</Tag>
  )
}

const whiteSpace = system({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
})

export default styled(BareText)`
  font-weight: 400;
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${space}
  ${color}
  ${display}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${system({
    whiteSpace: true,
  })}
`
